/* eslint-disable */

import { IJsonResponse, ModelState } from "@cloudfun/core";
import { Module } from "vuex";

const apiPath = "/Terms";

interface State {
    value: string;
}

const module: Module<State, ModelState> = {
    namespaced: true,
    state: {
        value: ''
    },
    mutations: {
        setValue(state, value) {
            state.value = value;
        }
    },
    actions: {
        read: ({ rootState, state, getters, commit }, type: number) => new Promise((resolve, reject) => {
                rootState.clients.unauthorized.get(`${apiPath}/${type}`).then(
                    (success: IJsonResponse<any>) => {
                        commit('setValue', success.payload);
                        resolve(state.value);
                    },
                    failure => reject(failure.message)
                );
        }),
        save: ({ rootState, state, commit }, value: any) => new Promise((resolve, reject) => {
            rootState.clients.authorized.put(apiPath, value).then(
                (success: IJsonResponse<any>) => {
                    commit('setValue', success.payload);
                    resolve(state.value);
                },
                failure => reject(failure.message)
            );
        }),
    }
}

export default module;