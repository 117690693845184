import { Model } from "@cloudfun/core";
import { main, Store as MainStore, State as MainState } from "./main";
import configuration from "./configuration";
import actionLog from "./action-log";
import person from "./person";
import role from "./role";
import user from "./user";
import bannerPosition from "./banner-position";
import banner from "./banner";
import merchant from "./merchant";
import contract from "./contract";
import store from "./store";
import serviceCategory from "./service-category";
import skillCategory from "./skill-category";
import supportableFacility from "./supportable-facility";
import paymentMethod from "./payment-method";
import term from "./term";
import pictureAlbum from "./picture-album";
import picture from "./picture";

const model = new Model('Ulamei', {
  main,
  configuration,
  actionLog,
  person,
  role,
  user,
  bannerPosition,
  banner,
  merchant,
  contract,
  store,
  serviceCategory,
  skillCategory,
  supportableFacility,
  paymentMethod,
  term,
  pictureAlbum,
  picture
});

const backendServer = process.env.VUE_APP_BACKEND_URL;
model.clients.authorized = model.createHttpClient(`${backendServer}/api`, true);
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`);
model.onLogin = (data) => model.clients.unauthorized.post('System/Login', data);
model.onLogout = () => model.clients.authorized.post('System/Logout');
model.onReloadUser = () => model.clients.authorized.post('System/CurrentUser');
model.onReloadEnums = () => model.clients.unauthorized.get('System/Enumerations');
model.onLog = (message) => model.clients.unauthorized.post('System/Log', message);

export type State = { main: MainState };
export type Store = MainStore<Pick<State, "main">>;

export default model;
