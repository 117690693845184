import { ISitemapNode } from "@cloudfun/core";

const sitemap: ISitemapNode = {
  icon: "HomeIcon",
  to: '',
  title: "Home",
  subNodes: [
    {
      icon: "fas-tachometer-alt",
      to: 'dashboard',
      title: "儀錶板",
    },
    {
      icon: "fas-cog",
      to: 'configuration',
      title: "系統配置",
    },
    {
      icon: "fas-bars",
      to: 'action-log',
      title: "操作紀錄",
    },
    {
      icon: 'fa-magic',
      to: 'store-wizard',
      title: '開店精靈'
    },
    {
      icon: "fas-id-card",
      to: 'person',
      title: "個資管理",
    },
    {
      icon: "fas-id-card-alt",
      title: "帳戶管理",
      subNodes: [
        {
          icon: "fa-user-tag",
          to: 'role',
          title: "角色管理",
        },
        {
          icon: "fas-user",
          to: 'user',
          title: "用戶管理",
        }
      ]
    },
    {
      icon: "fas-audio-description",
      to: 'banner',
      title: "廣告管理",
    },
    {
      icon: "fas-user-tie",
      to: 'merchant',
      title: "商家管理",
    },
    {
      icon: "fas-file-contract",
      to: 'contract',
      title: "契約管理",
    },
    {
      icon: "fas-store",
      to: 'store',
      title: "店家管理",
    },
    {
      icon: "fas-sitemap",
      title: "類別管理",
      subNodes: [
        {
          icon: "fas-concierge-bell",
          to: 'service-category',
          title: "服務類別",
        },
        {
          icon: "fas-award",
          to: 'skill-category',
          title: "技能類別",
        }
      ]
    },
    {
      icon: "fas-icons",
      to: 'supportable-facility',
      title: "提供設施",
    },
    {
      icon: "fas-money-check-alt",
      to: 'payment-method',
      title: "支付方式",
    },
    {
      icon: "fas-peace",
      to: 'term',
      title: "條款管理",
    },
  ]
};

export default sitemap;
