/* Fromat: {  culture-code: localization-json } */

import vxeTableZhTWLocale from "vxe-table/lib/locale/lang/zh-TW";

const localization = {
  "zh-Hant": require('./zh-Hant.json'),
  "en-US": require('./en-US.json')
}
Object.assign(localization["zh-Hant"], vxeTableZhTWLocale);

export default localization;
