import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import ErrorPage from "./views/error-page/Main.vue";

const viewRotes: RouteRecordRaw[] = [
  // project views
  { path: "dashboard", component: () => import("./views/dashboard/Main.vue") },
  {
    path: "configuration",
    component: () => import("./views/configuration/Main.vue"),
    meta: { rule: "Configuration" }
  },
  {
    path: "action-log",
    component: () => import("./views/action-log/Main.vue"),
    meta: { rule: "ActionLog" }
  },
  {
    path: "person",
    component: () => import("./views/person/Main.vue"),
    meta: { rule: "Person" }
  },
  {
    path: "role",
    component: () => import("./views/role/Main.vue"),
    meta: { rule: "Role" }
  },
  {
    path: "user",
    component: () => import("./views/user/Main.vue"),
    meta: { rule: "User" }
  },
  {
    path: "banner",
    component: () => import("./views/banner/Main.vue"),
    meta: { rule: "BannerPosition" }
  },
  {
    path: "merchant",
    component: () => import("./views/merchant/Main.vue"),
    meta: { rule: "Merchant" }
  },
  {
    path: "contract",
    component: () => import("./views/contract/Main.vue"),
    meta: { rule: "Contract" }
  },
  {
    path: "store",
    component: () => import("./views/store/Main.vue"),
    meta: { rule: "Store" }
  },
  {
    path: "service-category",
    component: () => import("./views/service-category/Main.vue"),
    meta: { rule: "ServiceCategory" }
  },
  {
    path: "skill-category",
    component: () => import("./views/skill-category/Main.vue"),
    meta: { rule: "SkillCategory" }
  },
  {
    path: "supportable-facility",
    component: () => import("./views/supportable-facility/Main.vue"),
    meta: { rule: "SupportableFacility" }
  },
  {
    path: "payment-method",
    component: () => import("./views/payment-method/Main.vue"),
    meta: { rule: "PaymentMethod" }
  },
  {
    path: "term",
    component: () => import("./views/term/Main.vue"),
    meta: { rule: "Terms" }
  },
  {
    path: "store-wizard",
    component: () => import("./views/store-wizard/Main.vue"),
    meta: { rule: "Person" }
  }
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/side-menu/Main.vue")
  },
  {
    path: "/simple-menu",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/simple-menu/Main.vue")
  },
  {
    path: "/top-menu",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/top-menu/Main.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/login/Main.vue")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./views/register/Main.vue")
  },
  { path: "/error-page", name: "error-page", component: ErrorPage },
  { path: "/:pathMatch(.*)*", component: ErrorPage }
];

export default createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});
